<template>
  <div class="pl-3">
    <div v-if="!connected" class="mb-4">
    <base-switch
    v-model="on"
    onText="On"
    offText="Off"
    >
    </base-switch>
    </div>
    <ValidationObserver v-slot="{ invalid }" ref="validator">
      <template v-if="!connected">
        <base-input
          type="text"
          v-model="accessKey"
          rules="customRequired"
          name="API key"
          label="Enter your Access key"
          :disabled="!on"
        >
        </base-input>
        <base-input
          type="text"
          v-model="secretKey"
          rules="customRequired"
          name="API key"
          label="Enter your Secret key"
          :disabled="!on"
        >
        </base-input>
      </template>
      <connection-buttons
      :connected="connected"
      :disabled="!on || invalid"
      @connect="connect"
      @disconnect="$emit('disconnect')"
      />
    </ValidationObserver>
  </div>
</template>

<script>
import ConnectionButtons from './ConnectionButtons.vue';

export default {
  components: {
    ConnectionButtons,
  },
  props: {
    type: String,
    connected: Boolean,
  },
  data: function() {
    return {
      on: false,
      accessKey: '',
      secretKey: '',
    };
  },
  methods: {
    connect: async function() {
      if (await this.$refs.validator.validate()) {
        const payload = {
          atsIntegrationEnabled: this.on,
          atsType: this.type,
          atsApiCredentials: {
            accessKey: this.accessKey,
            secretKey: this.secretKey,
          },
        };
        this.$emit('test-connection', {payload});
      }
    },
  },
};
</script>
