export const plansTypes = {
  FREE: 'free',
  PAYG: 'payg',
  MONTHLY: 'monthly',
  ANNUAL: 'annual',
};

export const userTypes = {
  ADMIN: 'admin',
  MEMBER: 'member',
};

export const accountTypes = {
  NONE: 'none',
  PERSONAL: 'personal',
  TEAM: 'team',
  RECTXT: 'rectxt',
};

export const seatStatus = {
  USED: 'used',
  AVAILABLE: 'available',
  PENDING_INVITATION: 'pendingInvitation',
  RECTXT: 'rectxt',
};

export const paymentModes = {
  PREPAID: 'prepaid',
  POSTPAID: 'postpaid',
  INVOICING: 'invoicing',
};

export const supportUrl = `${process.env.VUE_APP_SUPPORT_URL}/articles/`;

export const atsIntegrationsList = [
  {
    name: 'Greenhouse',
    type: 'greenhouse',
    logo: 'img/integrations/green-house.png',
    headerLogo: '/img/integrations/headers/green-house.png',
    componentName: 'greenhouse',
    help: `${supportUrl}3976084-how-to-integrate-greenhouse-ats-to-rectxt`,
  },
  {
    name: 'Greenhouse Onboarding',
    type: 'greenhouseOnboarding',
    logo: 'img/integrations/greenhouse-onboarding.png',
    headerLogo: '/img/integrations/headers/greenhouse-onboarding.png',
    componentName: 'greenhouse-onboarding',
    help: `${supportUrl}3976084-how-to-integrate-greenhouse-ats-to-rectxt`,
  },
  {
    name: 'SmartRecruiters',
    type: 'smartrecruiters',
    logo: 'img/integrations/smart-recruiter.png',
    headerLogo: '/img/integrations/headers/smart-recruiter.png',
    componentName: 'smart-recruiters',
    help: `${supportUrl}4125646-how-to-integrate-smartrecruiters-ats-to-rectxt`,
  },
  {
    name: 'Lever',
    type: 'lever',
    logo: 'img/integrations/lever.png',
    headerLogo: '/img/integrations/headers/lever.png',
    componentName: 'lever',
    help: `${supportUrl}6182702-how-to-integrate-lever-hire-with-rectxt`,
  },
  {
    name: 'Jobadder',
    type: 'jobadder',
    logo: 'img/integrations/jobadder.png',
    headerLogo: '/img/integrations/headers/jobadder.png',
    componentName: 'jobadder',
    help: `${supportUrl}4632659-how-to-integrate-jobadder-ats-with-rectxt`,
  },
  {
    name: 'FreshTeam',
    type: 'freshteam',
    logo: 'img/integrations/freshteam.png',
    headerLogo: '/img/integrations/headers/freshteam.png',
    componentName: 'fresh-team',
    help: `${supportUrl}5864877-how-to-integrate-freshteam-ats-with-rectxt`,
  },
  {
    name: 'PinPoint',
    type: 'pinpoint',
    logo: 'img/integrations/pinpoint.png',
    headerLogo: '/img/integrations/headers/pinpoint.png',
    componentName: 'pin-point',
    help: `${supportUrl}9967587-how-to-connect-keeyora-with-your-pinpoint-ats`,
  },
  {
    name: 'Recruitee',
    type: 'recruitee',
    logo: 'img/integrations/recruitee.png',
    headerLogo: '/img/integrations/headers/recruitee.png',
    componentName: 'recruitee',
    help: `${supportUrl}6467141-how-to-integrate-recruitee-ats-to-rectxt`,
  },
  {
    name: 'Comeet',
    type: 'comeet',
    logo: 'img/integrations/comeet.png',
    headerLogo: '/img/integrations/headers/comeet.png',
    componentName: 'comeet',
    help: `${supportUrl}6536351-finding-your-comeet-api-key`,
  },
  {
    name: 'Ashby',
    type: 'ashby',
    logo: 'img/integrations/ashby.png',
    headerLogo: '/img/integrations/headers/ashby.png',
    componentName: 'ashby',
    help: `${supportUrl}6736503-how-to-integrate-rectxt-to-ashby-ats`,
  },
  {
    name: 'CrelateTalent',
    type: 'crelate',
    logo: 'img/integrations/crelate.png',
    headerLogo: '/img/integrations/headers/crelate.png',
    componentName: 'crelate-talent',
    help: `${supportUrl}3975761-how-to-connect-crelate-ats-to-rectxt`,
  },
];

export const seatTypes = {
  TEXTER: 'texter',
  NON_TEXTER: 'nonTexter',
};

export const analyticsHide = ['cwerner@stillwaterhc.com'];

export const templateTypes = {
  AFTER: 'after',
  BEFORE: 'before',
  SAME_DAY: 'onTime',
};
